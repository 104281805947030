<template>
    <div class="home-view">
        <section class="hero-section">
            <container>
                <a-row type="flex" align="middle" :gutter="[16, 16]">
                    <a-col :md="12">
                        <div class="item-content">
                            <div class="item-content_title">
                                <h2>Explore Your Fabric on a Realistic Sofa, Pillows, and Curtains</h2>
                                <p>
                                    We appreciate your thoughts and feelings about fabric furniture. So we created an entire virtual environment to simulate the
                                    look and feel of our fabrics on actual sofas, curtains, and pillows. You can now save money and see your fabrics in action
                                    before purchasing them.
                                </p>
                            </div>
                            <div class="item-content_button">
                                <router-link to="/customizer">
                                    <span> Start Customize </span>
                                </router-link>
                                <a href="#video" class="video_btn">
                                    <span> Watch Video </span>
                                </a>
                            </div>
                        </div>
                    </a-col>
                    <a-col :md="12">
                        <div class="item-image">
                            <img src="/image/hero.png" />
                        </div>
                    </a-col>
                </a-row>
            </container>
        </section>
        <section class="work-section">
            <container>
                <a-row>
                    <a-col :span="24">
                        <div class="work-title">
                            <h2>How it Works</h2>
                            <p>Bulkit is optimized to speed up your development workflow.</p>
                        </div>
                    </a-col>
                </a-row>
                <a-row :gutter="[16, 16]">
                    <a-col :span="24" :md="12" :lg="6">
                        <div class="work-item">
                            <div class="icon">
                                <img src="/icon/work-icon-1.svg" alt="" />
                            </div>
                            <h3>
                                <span>01</span>
                                Select the type of Fabric Materials you want
                            </h3>
                            <!-- <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                aliquam erat volutpat.
                            </p> -->
                        </div>
                    </a-col>
                    <a-col :span="24" :md="12" :lg="6">
                        <div class="work-item">
                            <div class="icon">
                                <img src="/icon/work-icon-2.svg" alt="" />
                            </div>
                            <h3>
                                <span>02</span>
                                Enter the materials and get the real look right away
                            </h3>
                            <!-- <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                aliquam erat volutpat.
                            </p> -->
                        </div>
                    </a-col>
                    <a-col :span="24" :md="12" :lg="6">
                        <div class="work-item">
                            <div class="icon">
                                <img src="/icon/work-icon-3.svg" alt="" />
                            </div>
                            <h3>
                                <span>03</span>
                                You can buy the included Fabric Materials
                            </h3>
                            <!-- <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                aliquam erat volutpat.
                            </p> -->
                        </div>
                    </a-col>
                    <a-col :span="24" :md="12" :lg="6">
                        <div class="work-item">
                            <div class="icon">
                                <img src="/icon/work-icon-4.svg" alt="" />
                            </div>
                            <h3>
                                <span>04</span>
                                If you need it again, you can download it as an image
                            </h3>
                            <!-- <p>
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna
                                aliquam erat volutpat.
                            </p> -->
                        </div>
                    </a-col>
                </a-row>
            </container>
        </section>
        <section class="quickstart-section" id="video">
            <container>
                <a-row>
                    <a-col :span="24">
                        <div class="quickstart-title">
                            <h2>Quick Start</h2>
                            <p>Don't forget to watch our quick start tutorial.</p>
                        </div>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="24">
                        <div class="quickstart-video">
                            <video width="320" height="240" controls>
                                <source src="/video/intro-video.mp4" type="video/mp4">
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </a-col>
                </a-row>
            </container>
        </section>
    </div>
</template>

<script>
// @ is an alias to /src

import Container from "@/components/Container/Index";

export default {
    name: "Home",
    components: {
        Container,
    },
};
</script>

<style lang="scss">
.home-view {
    background-color: #ffffff;
    padding: 0 20px;

    .hero-section {
        padding: 120px 0;
        /* background-color: #eae0d5; */
        .item-content {
            &_title {
                h2 {
                    font-size: 54px;
                    line-height: 1;
                    font-weight: 900;
                    font-family: "Cardo", serif;
                }
                p {
                    line-height: 1.8;
                    margin: 20px 0;
                }
            }
            &_button {
                display: flex;

                a {
                    display: block;
                    color: #ffffff;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 4px;
                    padding: 18px 26px;
                    margin-right: 30px;
                    background-color: #94632d;
                    text-decoration: none;
                    transition: all ease 450ms;

                    span {
                        color: inherit;
                    }

                    &:hover {
                        color: #000000;
                        background-color: #ffffff;
                    }

                    &.video_btn {
                        color: #000000;
                        background-color: #ffffff;

                        &:hover {
                            color: #ffffff;
                            background-color: #94632d;
                        }
                    }
                }
            }
        }
        .item-image {
            position: relative;
            padding: 10px 0;

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 50%;
                background-color: rgba(#fff, 0.45);
            }

            img {
                max-width: 100%;
                margin-bottom: -4px;
                position: relative;
                z-index: 10;
            }
        }
    }
    .work-section {
        padding: 120px 0;
        .work-title {
            margin-bottom: 80px;
            text-align: center;

            h2 {
                color: #94632d;
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                font-size: 20px;
            }
        }
        .work-item {
            padding: 35px;
            background: #ffffff;
            border-radius: 16px;
            text-align: center;
            height: 100%;
            border: 1px solid #94632d;
            transition: all ease 450ms;

            &:hover {
                border-color: transparent;
                box-shadow: 0px 5px 20px rgba(#000000, 0.2);
            }

            .icon {    
                max-width: 180px;
                max-height: 180px;
                margin: auto;
                padding: 45px;
                border-radius: 50%;
                border: 2px dashed #94632d;

                img {
                    width: 100%;
                    max-width: 100%;
                }
            }

            h3 {
                color: #94632d;
                font-size: 20px;
                margin: 15px 0;
                font-weight: 600;
                span {
                    font-size: 45px;
                    color: inherit;
                    display: block;
                    font-weight: 600;
                }
            }

            p {
                line-height: 1.6;
                font-weight: 500;
            }
        }
    }

    .quickstart-section {
        padding: 120px 0;
        .quickstart-title {
            margin-bottom: 80px;
            text-align: center;

            h2 {
                color: #94632d;
                font-size: 40px;
                margin-bottom: 20px;
            }
            p {
                font-size: 18px;
            }
        }
        .quickstart-video {    
            background-color: #94632d;
            padding: 8px;
            video {
                height: auto;
                width: 100%;
            }
        }
    }
}
</style>
