<template>
    <a-row>
        <a-col :md="4"></a-col>
        <a-col :md="16">
            <slot />
        </a-col>
        <a-col :md="4"></a-col>
    </a-row>
</template>

<script>
export default {
    name: "Container",
};
</script>
